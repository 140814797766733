import React, { FC } from 'react';
import { graphql } from 'gatsby';

import Layout from 'gatsby-theme-husky/src/layout/Layout';
import { SimplepageTypes } from '@shared/types/umbraco/content/simplepage';
import FaqSection from 'gatsby-theme-husky/src/components/FaqSection';
import SimpleBanner from 'components/SimpleBanner';
import BodyRenderer from 'gatsby-theme-husky/src/common/BodyRenderer';
import WideBanner from 'gatsby-theme-husky/src/components/WideBanner';
import SlimBanner from 'gatsby-theme-husky/src/components/SlimBanner';
import InfoBanner from 'components/InfoBanner';
import TextSection from 'components/TextSection';
import ProductListingBanner from 'components/ProductListingBanner';
import RelatedArticles from 'components/RelatedArticles';
import DescriptionGridLink from 'common/DescriptionGridLink';
import SignUp from 'components/SignUp';
import ProductsDifferencesWrapper from 'components/ProductsDifferences/ProductsDifferencesWrapper';
import ProductsDifferences from 'components/ProductsDifferences';
import ProductsSliderBanner from 'components/ProductsSliderBanner';
import ThankYou from 'common/ThankYou';
import SectionWithCarousel from 'components/SectionWithCarousel';
import QuestionsCarousel from 'components/SupportPage/QuestionsCarousel';
import QuestionsBlock from 'components/SupportPage/QuestionsBlock';
import InstructionsStepList from 'components/InstructionsStepList';
import Instructions from 'components/Instructions';
import IframeBlock from 'components/IframeBlock';
import StepList from 'components/StepList';

import './SimplePage.scss';

const elements = {
  'Slim banner': ({ properties }, keyId) => <SlimBanner key={keyId} {...properties} />,
  'FAQ Section': ({ properties }, keyId) => <FaqSection key={keyId} {...properties} />,
  'Info Banner Wide': ({ properties }, keyId) => <WideBanner key={keyId} {...properties} />,
  'Info Banner': ({ properties }, keyId) => <InfoBanner key={keyId} {...properties} />,
  'Text Section': ({ properties }, keyId) => <TextSection key={keyId} {...properties} />,
  Partners: ({ properties }, keyId) => <DescriptionGridLink key={keyId} {...properties} />,
  'Signup Placeholder': ({ properties }, keyId) => <SignUp key={keyId} {...properties} />,
  'Product Listing Banner': ({ properties }, keyId) => (
    <ProductListingBanner key={keyId} {...properties} />
  ),
  'Related Articles': ({ properties }, keyId) => <RelatedArticles key={keyId} {...properties} />,
  'Thank You': ({ properties }, keyId) => <ThankYou key={keyId} {...properties} />,
  'Products Differences': ({ properties }, keyId) => (
    <ProductsDifferencesWrapper key={keyId} {...properties} />
  ),
  'Products Differences Data': ({ properties }, keyId) => (
    <ProductsDifferences key={keyId} {...properties} />
  ),
  'Products Slider Banner': ({ properties }, keyId) => (
    <ProductsSliderBanner key={keyId} {...properties} />
  ),
  'Instructions List': ({ properties }, keyId) => (
    <InstructionsStepList key={keyId} properties={properties} />
  ),
  'Iframe Block': ({ properties }, keyId) => <IframeBlock key={keyId} {...properties} />,
  Instructions: ({ properties }, keyId) => <Instructions key={keyId} properties={properties} />,
  'Step List Structure': ({ properties }, keyId) => (
    <StepList key={keyId} properties={properties} />
  ),
  'Section with carousel': ({ properties }, keyId) => {
    const { text, card, color, carouselMode, button, waiSettings, verticalCardMode } = properties;
    const questionCards = card.map((item) => item.properties);

    return (
      <SectionWithCarousel
        key={keyId}
        text={text}
        color={color}
        cardsBlock={
          carouselMode ? (
            <QuestionsCarousel
              questionCards={questionCards}
              ariaNext={waiSettings?.ariaNext}
              ariaPrev={waiSettings?.ariaPrev}
              isVerticalCardMode={verticalCardMode}
            />
          ) : (
            <QuestionsBlock questionCards={questionCards} isVerticalCardMode={verticalCardMode} />
          )
        }
        button={button}
      />
    );
  },
};

const SimplePage: FC<SimplepageTypes.IProperties> = ({
  pageContext: { breadCrumbs, link, searchUrl, relatedArticlesLinks },
  data: {
    page: {
      nodes: [
        {
          banner,
          defaultCompositions,
          body,
          seoMetaKeywords,
          seoMetaTitle,
          seoMetaTitleTemplate,
          seoMetaDescription,
          seoExternalHreflangs,
          seoNoIndex,
          seoCanonicalUrl,
          alternateUrls,
          ogImageUrl,
          hideHeader,
          hideFooter,
        },
      ],
    },
    relatedProducts,
    relatedArticles,
    selectedArticlesManual,
  },
}) => {
  const { singupFormBaner, waiSettings } = defaultCompositions;
  const imageUrl = ogImageUrl || banner?.[0]?.properties?.image?.fallbackUrl;
  const imageAlt = banner?.[0]?.properties?.title;

  return (
    <Layout
      {...{
        ...defaultCompositions,
        seoMetaKeywords,
        seoMetaTitle,
        seoMetaTitleTemplate,
        seoNoIndex,
        seoMetaDescription,
        seoExternalHreflangs,
        seoCanonicalUrl,
        skipText: waiSettings?.skipText,
        link,
        searchUrl,
        alternateUrls,
        imageUrl,
        imageAlt,
        isHideHeader: hideHeader,
        isHideFooter: hideFooter,
      }}
      pageClassName="simple-page"
    >
      {banner?.length ? <SimpleBanner {...{ ...banner[0].properties, breadCrumbs }} /> : null}

      <BodyRenderer
        bodyData={body}
        bodyStructure={elements}
        bodyItemProps={{
          ...{
            singupFormBaner,
            relatedProducts,
            relatedArticles,
            relatedArticlesLinks,
            selectedArticlesManual,
          },
        }}
      />
    </Layout>
  );
};
export const query = graphql`
  query allSimpleQuery(
    $link: String = ""
    $relatedProductLinks: [String]
    $relatedArticlesLinks: [String]
    $selectedArticlesLinks: [String]
  ) {
    relatedProducts: allProduct(filter: { link: { in: $relatedProductLinks } }) {
      nodes {
        link
        ean
        useRedirects
        tags {
          isCategory
          isFamily
          color {
            label
          }
          isLabel
          title
        }
        title
        cardDescription
        shortDescription
        images {
          properties {
            imageAlt
            image {
              ...FragmentGatsbyProps
              gatsbyImage {
                childImageSharp {
                  fluid(maxWidth: 400) {
                    ...GatsbyImageSharpFluid_withWebp_noBase64
                  }
                }
              }
            }
          }
        }
      }
    }
    relatedArticles: allArticle(filter: { link: { in: $relatedArticlesLinks } }) {
      nodes {
        link
        tags {
          isCategory
          isLabel
          title
        }
        title
        lang
        date
        dateSufix
        image {
          properties {
            image {
              ...FragmentGatsbyProps
              gatsbyImage {
                childImageSharp {
                  fluid(maxWidth: 300) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
            imageAlt
          }
        }
      }
    }
    selectedArticlesManual: allArticle(filter: { link: { in: $selectedArticlesLinks } }) {
      nodes {
        link
        title
        lang
        date
        dateSufix
        image {
          properties {
            image {
              ...FragmentGatsbyProps
              gatsbyImage {
                childImageSharp {
                  fluid(maxWidth: 300) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
            imageAlt
          }
        }
      }
    }
    page: allSimple(filter: { link: { eq: $link } }) {
      nodes {
        seoMetaTitle
        seoMetaTitleTemplate
        seoMetaDescription
        seoMetaKeywords
        seoNoIndex
        seoExternalHreflangs {
          key
          value
        }
        seoCanonicalUrl {
          url
        }
        alternateUrls {
          lang
          url
          path
        }
        ogImageUrl
        hideHeader
        hideFooter
        defaultCompositions {
          footer {
            ...FragmentFooter
          }
          header {
            ...FragmentHeader
          }
          siteSettings {
            ...FragmentSiteSettingsComposition
          }
          brandSettings {
            ...FragmentBrandSettingsComposition
          }
          singupFormBaner {
            ...FragmentSignUpBanner
          }
          waiSettings {
            skipText
          }
          warning {
            ...FragmentWarning
          }
          signUpPopup {
            ...FragmentSignUpPopup
          }
          signUpFormPopup {
            ...FragmentSignUpFormPopup
          }
          promoPlatformForm {
            ...FragmentPromoPlatformForm
          }
        }
        banner {
          ...FragmentSimpleBanner
        }
        body {
          ... on TSlimbanner {
            ...FragmentSlimBanner
          }
          ... on TFAQSection {
            ...FragmentFaqSection
          }
          ... on TInfoBanner {
            ...FragmentInfoBanner
          }
          ... on TInfoBannerWide {
            ...FragmentWideBanner
          }
          ... on TTextSection {
            ...FragmentTextSection
          }
          ... on TPartners {
            ...FragmentPartners
          }
          ... on TSignupPlaceholder {
            ...FragmentSignUp
          }
          ... on TProductListingBanner {
            ...FragmentProductListingBanner
          }
          ... on TRelatedArticles {
            ...FragmentRelatedArticles
          }
          ... on TThankYou {
            ...ThankYouFragment
          }
          ... on TProductsDifferencesData {
            ...FragmentProductsDifferences
          }
          ... on TProductsDifferences {
            ...FragmentProductsDifferencesWrapper
          }
          ... on TProductsSliderBanner {
            ...FragmentProductsSliderBanner
          }
          ... on TSectionwithcarousel {
            ...FragmentSectionWithCarousel
          }
          ... on TInstructionsList {
            ...FragmentInstructionsList
          }
          ... on TInstructions {
            ...FragmentInstructions
          }
          ... on TIframeBlock {
            ...FragmentIframeBlock
          }
          ... on TStepListStructure {
            ...FragmentStepList
          }
        }
      }
    }
  }
`;

export default SimplePage;
