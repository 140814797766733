import React, { FC } from 'react';
import { graphql } from 'gatsby';
import classNames from 'classnames';

import GatsbyImage from 'common/GatsbyImage';
import DangerouslySetInnerHtml from 'common/DangerouslySetInnerHtml';
import Button from 'common/Button';

import { IThankYouProps } from './model';

import './ThankYou.scss';

const ThankYou: FC<IThankYouProps> = ({ image, text, cta, sectionBackground, additionalText }) => (
  <div
    className={classNames('thank-you', {
      [`thank-you--${sectionBackground?.label}`]: sectionBackground?.label,
    })}
  >
    <div className="thank-you__container">
      {image?.length ? (
        <div className="thank-you__image">
          <GatsbyImage
            image={image[0].properties.image}
            alt={image[0].properties.imageAlt}
            isLazyLoading={false}
          />
        </div>
      ) : null}
      <DangerouslySetInnerHtml className="thank-you__text" html={text} />
      {cta?.length
        ? cta.map((el) => (
            <Button
              key={el.url}
              classes="thank-you__cta"
              to={el.url}
              ariaLabel={el.name}
              target={el.target}
              variant="primary"
            >
              {el.name}
            </Button>
          ))
        : null}
      <DangerouslySetInnerHtml className="thank-you__additional-text" html={additionalText} />
    </div>
  </div>
);

export const query = graphql`
  fragment ThankYouFragment on TThankYou {
    properties {
      image {
        properties {
          image {
            ...FragmentGatsbyProps
            gatsbyImage {
              childImageSharp {
                fluid(maxWidth: 200) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
            svg {
              content
            }
          }
          imageAlt
        }
      }
      text
      additionalText
      cta {
        name
        url
        target
      }
      sectionBackground {
        label
      }
    }
    structure
  }
`;

export default ThankYou;
