import React, { FC } from 'react';
import { graphql } from 'gatsby';

import { Container, DangerouslySetInnerHtml } from 'layout';
import GatsbyImage from 'common/GatsbyImage';
import Icon from 'common/IconCustom';

import { IInstructions } from './model';

import './Instructions.scss';

const Instructions: FC<IInstructions> = ({ properties }) => (
  <section className="instructions">
    <div className="instructions__header-container">
      <Container fluid>
        <DangerouslySetInnerHtml className="instructions__header" html={properties.text} />
      </Container>
    </div>
    {properties.instructionsItem?.length ? (
      <Container className="instructions__list" fluid>
        {properties.instructionsItem.map(
          ({ properties: { icon, imageIcon, imageAlt, description } }, idx) => (
            <div key={description.length + idx} className="instructions__list-item">
              {imageIcon && imageIcon.fallbackUrl ? (
                <GatsbyImage
                  image={imageIcon}
                  alt={imageAlt}
                  objectFit="contain"
                  className="instructions__list-item-icon"
                />
              ) : (
                <Icon icon={icon?.[0]} classes="instructions__list-item-icon" />
              )}
              <DangerouslySetInnerHtml
                className="instructions__list-item-description"
                html={description}
              />
            </div>
          )
        )}
      </Container>
    ) : null}
  </section>
);

export const query = graphql`
  fragment FragmentInstructions on TInstructions {
    structure
    properties {
      text
      instructionsItem {
        properties {
          description
          icon
          iconAlignment
          imageIcon {
            ...FragmentGatsbyProps
            gatsbyImage {
              childImageSharp {
                fixed(height: 36) {
                  ...GatsbyImageSharpFixed_withWebp
                }
              }
            }
          }
          imageAlt
        }
      }
    }
  }
`;

export default Instructions;
