import React, { FC } from 'react';
import classNames from 'classnames';
import { IImageCardGridProps } from '@shared/types';
import { Container } from 'layout';
import ImageCard from '../../common/ImageCard';
import './ImageCardGrid.scss';

const ImageCardGrid: FC<IImageCardGridProps> = ({ grid, classes }) => (
  <Container fluid>
    <div
      className={classNames('image-card-grid', {
        [`image-card-grid--${classes}-card`]: !!classes,
      })}
    >
      {grid.map((item, index) => (
        <ImageCard key={index} {...item.properties} />
      ))}
    </div>
  </Container>
);

export default ImageCardGrid;
